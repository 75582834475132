// import { Geolocation } from '@capacitor/geolocation';
//
// const getUserLocation = async (setUserLocation) => {
//     try {
//         const position = await Geolocation.getCurrentPosition();
//         const { latitude, longitude } = position.coords;
//         setUserLocation([latitude, longitude]);
//     } catch (error) {
//         console.error("Error getting user location:", error);
//     }
// };
//
// export default getUserLocation;

const getUserLocation = (setUserLocation) => {
    if (!navigator.geolocation) {
        console.error("Geolocation is not supported by your browser");
        return;
    }

    navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation([latitude, longitude]);
    }, (error) => {
        console.error("Error getting user location:", error);
    },
        {
        enableHighAccuracy: true,
        maximumAge: 1000
    });
};

export default getUserLocation;