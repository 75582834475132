import React from 'react';
import {IonButton, IonIcon} from '@ionic/react';
import {locate} from "ionicons/icons";
import getUserLocation from "../utils/getUserLocation";

// Componente LateralButtons
const LateralButtons = ({setNewCenter, setUserLocation}) => {
    // Función para manejar el evento de clic en el botón
    const handleClick = () => {
        getUserLocation(setNewCenter);
        getUserLocation(setUserLocation);
    };

    // Renderizado del componente
    return (
            <div style={{position: 'fixed', right: '10px', bottom: '10px'}}>
                    {/* Botón para obtener la ubicación del usuario */}
                    <IonButton onClick={handleClick}>
                        <IonIcon icon={locate}/>
                    </IonButton>
            </div>
    );
};

export default LateralButtons;