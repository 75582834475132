import React, {useState, useEffect} from 'react';

import SearchScreen from "../screens/SearchScreen";

const Tab2 = () => {


    return (
        <><SearchScreen/>
        </>
    );
};

export default Tab2;