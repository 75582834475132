import React, {useState, useCallback} from 'react';
import {
    IonModal, IonButton, IonHeader, IonToolbar, IonTitle, IonContent, IonText
} from '@ionic/react';
import {convertToDMSLetters} from "../utils/convertToDMS";
import {useTranslation} from "react-i18next";

const NearestPost = ({nearest, userLocation}) => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const handleClose = useCallback(() => setShowModal(false), []);
    const handleShow = useCallback(() => {
        if (userLocation) {
            setShowModal(true);
        }
    }, [userLocation]);

    const renderNearest = () => (<IonContent className="centered-content">
        <IonText color="primary">
            <h2 className="ion-color-primary"> {t('nearest.road')}<b>{nearest.road.name}</b></h2>
            <h2>{t('nearest.pk')}<b>{nearest.kilometer}</b></h2>
            <h5>{t('nearest.distance')}{nearest.distance * 1000} metros.</h5>
        </IonText>
        <hr/>
        <IonText color="primary">
            <h5>{t('nearest.coord')}</h5>
            <h3>{convertToDMSLetters(userLocation[0], 'latitude',t)}</h3>
            <h3>{convertToDMSLetters(userLocation[1], 'longitude',t)}</h3>
        </IonText>
    </IonContent>);
    const renderUserLocation = () => (<IonContent className="centered-content">
        <p>{t('nearest.coord2')}</p>
        <IonText color="primary">
            <h3>{convertToDMSLetters(userLocation[0], 'latitude',t)}</h3>
            <h3>{convertToDMSLetters(userLocation[1], 'longitude',t)}</h3>
        </IonText>
    </IonContent>);

    const renderUnavailableLocation = () => (<IonContent>
        <h3>{t('nearest.noloc')}</h3>
    </IonContent>);

    return (<div className="nearest">
        {userLocation ? (
            <div onClick={handleShow}>
                {nearest && nearest.distance < 1 ? (<>
                    <b className="">{t('nearest.pk2')}</b>
                    <br/><b>{nearest.road.name} </b>
                    km <b>{nearest.kilometer}</b><br/>{nearest.distance * 1000} {t('nearest.metres')}
                </>) : (<span>{t('nearest.nopoint')}</span>)}
            </div>
        ) : (
            <div>
                <span>{t('nearest.nogeoloc')}</span>
            </div>
        )}

        <IonModal className="pk-modal" isOpen={showModal} onDidDismiss={handleClose}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{t('nearest.pk2')}</IonTitle>
                    <IonButton slot="end" fill="clear" color="light" onClick={handleClose}>{t('misc.close')}</IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {nearest && nearest.distance < 1 ? renderNearest() : (userLocation ? renderUserLocation() : renderUnavailableLocation())}
            </IonContent>
        </IonModal>
    </div>);
};

export default NearestPost;