import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n.js';
import { Auth0Provider } from "@auth0/auth0-react";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { auth0Domain, clientId, callbackUri } from "./auth.config";
import { IonSpinner } from '@ionic/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Auth0Provider
            domain={auth0Domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: callbackUri
            }}
            // For using Auth0-React with Ionic on Android and iOS,
            // it's important to use refresh tokens without the fallback
            useRefreshTokens={true}
            useRefreshTokensFallback={true}
        >
            <Suspense fallback={
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <IonSpinner />
                </div>
            }>
                <App />
            </Suspense>
        </Auth0Provider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();