import React, {useEffect, useState} from "react";
import {useLocation, useHistory} from "react-router-dom";
import axios from "axios";
import NearestPost from '../components/NearestPost';
import getUserLocation from "../utils/getUserLocation";
import {headers, REST_BASE_URL} from "../constants";
import LateralButtons from "../components/LateralButtons";
import MapScreen from "../screens/MapScreen";

// Función para obtener los items de la API
const fetchItems = async (mapBounds) => {
    let url = `${REST_BASE_URL}posts/?west=${mapBounds.bounds.sw[1]}&south=${mapBounds.bounds.sw[0]}&east=${mapBounds.bounds.ne[1]}&north=${mapBounds.bounds.ne[0]}`;

    if (mapBounds.zoom < 11) {
        url += "&kilometer_multiple=10";
    } else if (mapBounds.zoom < 13) {
        url += "&kilometer_multiple=5";
    }

    try {
        const response = await axios.get(url, headers);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

// Función para obtener el nearest de la API
const fetchNearest = async (userLocation) => {
    let url = `${REST_BASE_URL}posts/nearest/?lat=${userLocation[0]}&lon=${userLocation[1]}`;

    try {
        const response = await axios.get(url, headers);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

// Componente principal
const Tab1 = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const lat = parseFloat(queryParams.get('lat'));
    const lng = parseFloat(queryParams.get('lng'));

    // Estados para los items, la ubicación del usuario, el nearest y los límites del mapa
    const [items, setItems] = useState();
    const [userLocation, setUserLocation] = useState(null);
    const [nearest, setNearest] = useState();
    const [mapBounds, setMapBounds] = useState({bounds: {ne: [0, 0], sw: [0, 0]}});
    const [mapCenter, setMapCenter] = useState(userLocation || [38.8885851, -6.9089432]);

    // Función para asignar un nuevo centro al mapa
    const setNewCenter = (newCenter) => {
        setMapCenter(newCenter);
    };

    // Función para manejar los cambios de límites del mapa
    const handleMapBoundsChanged = (bounds) => {
        setMapBounds(bounds);
        setMapCenter(bounds.center);
    }

    // Obtener los items cuando cambian los límites del mapa
    useEffect(() => {
        if (mapBounds.zoom > 9) {
            fetchItems(mapBounds).then(setItems);
        } else {
            setItems([]);
        }
    }, [mapBounds]);

    // Obtener el nearest cuando cambia la ubicación del usuario
    useEffect(() => {
        if (userLocation) {
            fetchNearest(userLocation).then(setNearest);
        }
    }, [userLocation]);


    useEffect(() => {
        // Centrar el mapa en las coordenadas de la URL si están presentes
        if (!isNaN(lat) && !isNaN(lng)) {
            setMapCenter([lat, lng]);
            history.replace('/map');
            getUserLocation((location) => {
                setUserLocation(location);
            });
            // Centrar el mapa en las coordenadas del usuario si no están presentes
        } else if (!userLocation) {
            getUserLocation((location) => {
                setUserLocation(location);
                setMapCenter(location);
            });
        }
    }, []);


    // Renderizado del componente
    if (!items) {
        return <p>loading...</p>
    } else {
        return (
            <div>
                <MapScreen items={items} onBoundsChanged={handleMapBoundsChanged} center={mapCenter}
                           userLocation={userLocation}/>
                <NearestPost nearest={nearest} userLocation={userLocation}/>
                <LateralButtons setNewCenter={setNewCenter} setUserLocation={setUserLocation}/>
            </div>
        );
    }
}

export default Tab1;