import {Clipboard} from "@capacitor/clipboard";

const writeToClipboard = async (coords) => {
    const [lng, lat] = coords
        .replace("SRID=4326;POINT (", "")
        .replace(")", "")
        .split(" ")
        .map(parseFloat);
    await Clipboard.write({
        string: `(${lat}, ${lng})`
    });
};

export default writeToClipboard;