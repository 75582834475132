import {IonButton, IonRow, IonToast} from "@ionic/react";
import React from "react";
import writeToClipboard from "../utils/writeToClipboard";
import {t} from "i18next";
import {clipboard} from "ionicons/icons";


const CopyCoord = ({selectedKilometer}) => {
    return (
        <IonRow class="ion-justify-content-center">
            <IonButton id="copy-coord" size="small" color="primary" fill="outline"
                       onClick={() => writeToClipboard(selectedKilometer.location)}
                       target="_blank" rel="noopener noreferrer">{t('misc.copyCoord')}</IonButton>
             <IonToast trigger="copy-coord"
                          message={t('misc.coords-copied')}
                          duration={200}
                          position="middle"
                          translucent={true}
                          color="primary"
                          icon={clipboard}
                ></IonToast>
        </IonRow>);
};

export default CopyCoord;