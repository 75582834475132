function convert(coord, type) {
    const absolute = Math.abs(coord);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    const direction = coord >= 0 ? 1 : -1;

    return {degrees, minutes, seconds, direction};
}

function convertToDMSLetters(coord, type, t) {
    const {degrees, minutes, seconds} = convert(coord, type);
    let directionLetter = '';
    if (type === 'latitude') {
        directionLetter = coord >= 0 ? t('dms.north') : t('dms.south');
    } else if (type === 'longitude') {
        directionLetter = coord >= 0 ? t('dms.east') : t('dms.west');
    }
    return `${degrees} ${t('dms.degrees')}, ${minutes} ${t('dms.minutes')}, ${seconds} ${t('dms.seconds')} ${directionLetter}`;
}

function convertToDMS(coord, type, t) {
    const {degrees, minutes, seconds} = convert(coord, type);
    let directionLetter = '';
    if (type === 'latitude') {
        directionLetter = coord >= 0 ? 'N' : 'S';
    } else if (type === 'longitude') {
        directionLetter = coord >= 0 ? 'E' : 'W';
    }
    return `${degrees}º ${minutes}' ${seconds}" ${directionLetter}`;
}

export {convertToDMSLetters, convertToDMS};